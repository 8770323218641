import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, navigate } from 'gatsby';
import useAxios from 'axios-hooks';

import { useUser } from '@util/providers/AuthProvider';
import { useInvoice } from '@util/providers/Invoice';
import useSubscription from '@util/hooks/useSubscription';
import { useAmendmentParams } from '@util/payment';

import URLS from '@config/urls';
import ProtectedPage from '@components/ProtectedPage';
import Go from '@util/CustomRedirect';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import OrderDetails from '@components/Invoice/OrderDetails';
import Disclaimer from '@components/Invoice/Disclaimer';

export default function MyPlanModifyReviewPage() {
  return (
    <ProtectedPage>
      <Rules>
        <PageContent />
      </Rules>
    </ProtectedPage>
  );
}

function PageContent() {
  const { t } = useTranslation();
  const { isSubscriber, billingDetails, paymentDetails } = useSubscription();
  const { invoicePreview, setInvoiceNumber, setInvoiceId } = useInvoice();
  const { ssoUser } = useUser();
  const amendmentParams = useAmendmentParams(false);

  const [
    {
      data: amendmentResponse,
      loading: submittingAmendment,
      //error
    },
    submitAmendment,
  ] = useAxios(
    {
      url: URLS.CREATE_AMENDMENT,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${ssoUser ? ssoUser.access_token : ''}`,
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (amendmentResponse) {
      setInvoiceNumber(amendmentResponse.results[0].invoiceNumber || 'pending');
      setInvoiceId(amendmentResponse.results[0].invoiceId || 'pending');
      navigate('/myplan/modify/thankyou', {
        state: {
          validPurchase: true,
        },
      });
    }
  }, [amendmentResponse, setInvoiceNumber, setInvoiceId]);

  if (!isSubscriber) {
    return null;
  }

  return (
    <>
      <SEO title={t('page:reviewOrder')} />
      <div className="row mb-4">
        <PageHeader className="col-12 col-sm-8">
          {t('page:reviewOrder')}
        </PageHeader>
        <div className="col-12 col-sm-4 text-sm-right mt-2">
          <Link to="/paymentdetails">{t('action.edit')} &rsaquo;</Link>
        </div>
      </div>
      <OrderDetails
        isReviewOrder
        billingDetails={billingDetails}
        paymentDetails={paymentDetails}
        amendment
      />
      <Disclaimer isReviewOrder amendment invoice={invoicePreview} />

      <div className="my-3">
        <div className="pt-4">
          <button
            type="button"
            onClick={() => {
              submitAmendment({ data: amendmentParams });
            }}
            disabled={submittingAmendment}
            className="btn btn-primary"
            id="submitPaymentButton"
          >
            {submittingAmendment && (
              <>
                <span
                  className="spinner-corner spinner-corner-sm align-middle mt-n1"
                  role="status"
                  aria-hidden="true"
                />{' '}
              </>
            )}
            {submittingAmendment ? t('status.submitting') : t('action.submit')}
          </button>
          <button
            type="button"
            onClick={() => navigate('/myplan')}
            className="btn btn-outline-secondary"
            disabled={submittingAmendment}
          >
            {t('action.cancel')}
          </button>
        </div>
      </div>
    </>
  );
}

function Rules({ children }) {
  const subscription = useSubscription();
  const { invoicePreview } = useInvoice();
  const homeRules = !subscription?.isSubscriber || !invoicePreview;
  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}
